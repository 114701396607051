.GlobalErrorsWrapper {
  align-items: center;
  display: flex;
  background-color: #b62323;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  min-height: 4rem;
  justify-content: center;
  padding: 0 1rem;
}

.GlobalErrors {
  align-items: center;
  display: flex;
  width: 100%;
}

.globalErrorIcon {
  flex-shrink: 0;
  margin-right: 1.5rem;
}

.globalErrorText {
  overflow: auto;
}

.closeBtn {
  flex-shrink: 0;
  margin-left: auto;
}
