.filterPanel {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(164, 169, 179, 0.50184);
  margin-bottom: 1rem;
}

.filterHr {
  height: 1px;
  background-color: rgba(164, 169, 179, 0.50184);
  margin: 1rem 0;
}

.innerFilterPanel {
  padding: 0 1rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--c-secondary-base);
  color: #fff;
  margin-right: 0.5rem;
}

.exportButton {
  font-size: 0.875rem;
  width: auto;
  height: 2.2rem;
}

.commoditySelect {
  width: 320px;
}

.mbrFilter,
.matrixFilter {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-left: 1rem;
  border-left: 2px solid #efefef;
}

.mbrOpBtn {
  border: none;
  width: 2.2rem;
  background: #efefef;
  border-radius: 2px;
  margin-left: 0.25rem;
  font-size: 1rem;
  line-height: 2.2rem;
  cursor: pointer;
  outline: none;
}

.mbrOpBtnSelected {
  background-color: var(--c-secondary-base);
  color: #fff;
}

.mbrPrcWrap {
  display: flex;
  align-items: center;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  height: 2.2rem;
  width: 60px;
  padding: 0 1rem;
  font-size: 1rem;
  color: #bbbbbb;
  font-weight: 600;
}

.mbrPrcInput {
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.clearFilter {
  align-items: center;
  display: flex;
  border: 1px solid var(--c-secondary-base);
  background-color: #fff;
  color: var(--c-secondary-base);
  line-height: 2rem;
  font-size: 0.75rem;
  padding: 0 0.5rem 0 0.75rem;
}

.clearFilterIcon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
}

.levelSelect {
  height: 36px;
  width: 60px;
}
.levelSelectWide {
  min-width: 100px;
}
.levelSelect select {
  /* Fix not fitting value */
  font-size: 80% !important;
}