.container {

}

.cardTable {
  width: 100%;
  min-height: 10rem;
  border-spacing: 0;
}
.cardTable th,
.cardTable td {
  border-bottom: 1px solid var(--c-gray);
}
.cardTable tr:first-child td  {
  border-top: 2px solid var(--c-gray);
}
.cardTable tr:last-child td {
  border-bottom-width: 2px;
}

.cardTable td:last-child {
  text-align: right;
}