.Select {
  height: 3.25rem;
}

.Select-control {
  height: 100%;
  border-radius: 0;
  border: 0 none;
}

.Select-value,
.Select-input,
.Select-placeholder {
  padding: 0.65rem;
}

.Select-value {
  font-size: 0.875rem;
  font-weight: 600;
}

.VirtualizedSelectOption {
  font-size: 0.875rem;
}

.Select-menu-outer {
  box-shadow: 0;
  border-radius: 0;
}

.Select-placeholder {
  color: var(--c-text-dark);
  font-size: 0.875rem;
}
