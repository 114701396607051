.container {
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  /* max-width: 24rem;
  min-width: 24rem; */
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--c-gray);
}

.export {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.export .button {
  padding-right: 0;
  outline: none;
}

.title {
  font-weight: 600;
  font-size: 1.125rem;
  text-decoration: none;
  color: var(--c-text-dark);
}

.title:hover {
  text-decoration: underline;
}

.expandDetails {
  color: var(--c-secondary-base);
  cursor: pointer;
}

.items {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.currencyLink {
  border-top: 1px solid var(--c-gray);
  padding-top: 1rem;
  margin-top: .5rem;
}
.currencyLink > a {
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: var(--c-secondary-base);
  text-decoration: none;
  font-weight: bold;
}
