.demoNumber {
  font-size: .8rem;
  color: #999;
}

.planOverviewPanel {
  background-color: #fff;
  padding: 1rem 0;
  box-shadow: 0 1px 3px rgba(164, 169, 179, 0.50184);
  margin-top: 0.5rem;
}
.planOverviewPanelContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toggleLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--c-secondary-base);
}

.icon {
  display: flex;
  color: var(--c-secondary-base);
  height: 18px;
  width: 18px;
}
.table {
  background-color: #fff;
  border-spacing: 0;
  width: 100%;
  font-size: 0.875rem;
}
.table tr {
  height: 3em;
}
.table th,
.table td {
  padding: 0 1em;
  text-align: right;
}
.table tr.bordered th {
  border-top: 1px solid var(--c-soft-blue);
}
.table td {
  border-top: 1px solid var(--c-soft-blue);
}
.table td.rowTitle {
  text-align: left;
  font-weight: 600;
}

.defaultText {
  font-weight: 400;
}
.exchangeRateGrid {
  position: relative;
  top: -.5rem;
  display: flex;
  grid-gap: 2rem;
  flex-wrap: wrap;
  margin: .2rem 1rem;
}
.recalcExchangeRateButton {
  width: 10rem;
  height: 2rem;
}
.refreshIconBtn {
  /* position: absolute;
  top: -8px;
  left: 8px; */
  position: relative;
  top: -4px;
  margin-top: 8px;

  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
}
.refreshIcon.rotating {
  animation: rotate 1.5s linear infinite;
}
.rotate {
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
