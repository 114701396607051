.button {
  height: 2.5rem;
  border: 0 none;
  width: 100%;
  background-color: var(--c-primary-base);
  font-size: 1rem;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
}

.secondary {
  background-color: var(--c-secondary-base);
}

.disabled {
  background-color: var(--c-background-blue);
}

.short {
  height: 2rem;
  font-size: 0.875rem !important;
}

.plain {
  background-color: transparent;
  width: auto;
  height: auto;
  color: var(--c-text-dark);
}

.plain.secondary {
  color: var(--c-secondary-base);
}

.hasContent {
  margin-right: 0.4rem;
}
