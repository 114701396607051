.container {
}

.table {
  width: 100%;
  border: 0 none;
  border-collapse: collapse;
}

.table th:first-child,
.table th:nth-child(2) {
  background-color: var(--c-gray);
}

.table th:nth-child(2),
.table td:nth-child(2) {
  border-right: 2px solid var(--c-secondary-base);
}

.table th {
  background-color: var(--c-secondary-light);
}

.table th:first-child {
  border-right: 1px solid #fff;
}

.table th,
.table td {
  height: 3rem;
  box-sizing: border-box;
  border: 0 none;
  padding: 0.5rem;
}

.table tbody tr:hover td {
  background-color: var(--c-gray);
}

.column {
  display: flex;
  align-items: center;
}

.limitColumn {
  width: 4rem;
  justify-content: center;
}

.selectLimit {
  font-size: 0.875rem;
  /* centers options in chrome */
  text-align-last: center;
  height: 100%;
}

.table th:last-child {
  background-color: var(--c-secondary-base);
  color: #fff;
  border-left: 1px solid #fff;
}

.table td:last-child {
  /* color: #fff; */
  border-left: 1px solid var(--c-secondary-base);
}
