.error {
  color: var(--c-error);
}

.header {
  padding: 1.5rem;
  border-bottom: 2px solid var(--c-gray);
}

.content {
  padding: 1.5rem;
}