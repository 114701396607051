.fixedSize {
  max-width: calc(1440px - 1rem);
  padding: 0 .5rem;
  margin: 0 auto;
}

.backButton {
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--c-text-dark);
  font-weight: 600;
  font-size: .875rem;
}