.header {
  padding: 1.5rem;
  border-bottom: 2px solid var(--c-gray);
}

.content {
  padding: 1.5rem;
}

.toggleButton {
  cursor: pointer;
  border: none;
  font-weight: bold;
  min-width: 44px;
  border-radius: 4px;
}

.activeBtn {
  color: #fff;
  background-color: var(--c-secondary-base);
}