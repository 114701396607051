.container {
    border-bottom: 2px solid var(--c-secondary-base);
    margin-bottom: 1.2rem;
}

.title {
    /* color: #484D59;  from figma */
    color: var(--c-text-dark);
    font-size: 1.125rem;
    line-height: 2.6rem;
    font-weight: 600;
    margin: 0;
}

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}


@keyframes dots {
  0%, 30% {
    color: transparent;
  }
  40% {
    color: var(--c-text-mid);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 var(--c-text-mid),
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 var(--c-text-mid),
      .5em 0 0 var(--c-text-mid);
  }
}
