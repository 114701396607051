.header {
  background-color: var(--c-secondary-dark);
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 4rem;
}

.headerWrapperFixed {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.appName {
  font-size: 1.5rem;
  margin: 0 1.2rem;
  padding: 1.25rem;
  min-width: 6.6rem;
  border-right: 1px solid #D8D8D8;
}

.link {
  text-decoration: none;
  color: #fff;
}

.headerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

.imgHeadingWrap {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.navigation {
  flex: 1;
}

.navItems {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none inside none;
}

.navItem {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.25rem 1.5rem 1rem;
  color: #fff;
}

.activeListMenu {
  position: absolute;
  top: 26px;
  left: 0;
  background-color: #fff;
  z-index: 100;
  min-width: 200px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
}

.activeListMenuItem {
  display: block;
  cursor: pointer;
  padding: .5rem 1rem;
  text-decoration: none;
  color: var(--c-text-dark);
}

.activeListMenuItem:hover {
  background-color: var(--c-soft-blue);
}