.container {
  position: relative;
  box-sizing: border-box;
  height: 2.75rem;
  width: 100%;
  background-color: white;
  border: 1px solid var(--c-gray);
}

.error {
  border-color: var(--c-error);
}

.input {
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  border: 0 none;
  color: var(--c-secondary-dark);
  font-size: 1.125rem;
  font-family: Montserrat, sans-serif;
}

.hasIcon {
  padding-left: 3rem;
}