.error {
  color: var(--c-error);
}

.header {
  padding: 1.5rem;
  border-bottom: 2px solid var(--c-gray);
}

.content {
  padding: 1.5rem;
}

.typeSelect {
  height: 2.75rem;
  padding: 0 1rem;
  font-size: 1.125rem;
  color: var(--c-secondary-dark);
  background-color: white;
  border: 1px solid var(--c-gray);
}