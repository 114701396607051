.tableSection {
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(164, 169, 179, 0.50184);
}

.optionsPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.exchangeRate {
  border: 1px solid var(--c-soft-blue);
  display: flex;
}
.exchangeRateGroup {
  align-items: center;
}

.checkboxLabel {
  font-weight: 600;
  font-size: 0.75rem;
  margin-right: .5rem;
}

.exchangeRateLabel {
  padding: 0.5rem .5rem 0.5rem 0.25rem;
}

.exchangeRateValue {
  padding: 0.5rem .25rem;
}

.inputNumber {
  font-size: 0.75rem;
  text-align: right;
  padding: 0.5rem;
  border: none;
  border-left: 1px solid var(--c-soft-blue);
  width: 4rem;
  outline: none;
}

.inputNumber:disabled {
  color: var(--c-soft-blue);
}

.table {
  background-color: #fff;
  border-spacing: 0;
  width: 100%;
  border-bottom: 1px solid var(--c-soft-blue);
}

.headerRow {
  height: 3rem;
  font-size: 0.875rem;
}

.headerRow th {
  top: 69px;
  position: sticky;
}

.headerGroupCell {
  text-align: left;
  background-color: var(--c-soft-blue);
  border-right: 1px solid #fff;
  padding: 0 1rem;
  font-weight: 600;
}

.row {
  height: 3rem;
}

.sameTitleSeparator {
  border-top: 2px solid var(--c-background-blue);
}

.commoditySeparator {
  border-top: 4px solid var(--c-background-blue);
}

.cell {
  padding: 0 0.25rem;
}

.cellFlexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowError {
  width: 1rem;
  height: 1rem;
  background-color: var(--c-error);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #fff;
}

.fitCol {
  width: 1px;
}

.headerHistoryCell {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: var(--c-secondary-light);
  border-right: 1px solid #fff;
  padding: 1rem;
  width: 1%;
}

.headerCurrentCell {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: var(--c-secondary-base);
  border-right: 1px solid #fff;
  padding: 1rem;
  width: 1%;
}

.headerDiffOrMBR {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: var(--c-text-mid);
  border-right: 1px solid #fff;
  padding: 1rem;
  width: 1%;
}

.leftSeparator {
  border-right: 1px solid var(--c-secondary-base);
}

.thickLeftSeparator {
  border-right: 2px solid var(--c-secondary-base);
}

.numberCell {
  text-align: center;
  padding: 1rem 0.25rem;
  min-width: calc(80px - 1px);
  box-sizing: border-box;
  font-size: 0.875rem;
}

.headerKeyCell {
  background-color: var(--c-key);
}

.keyCell {
  background-color: var(--c-key-row);
  width: 1%;
  height: 100%;
  padding: 0;
  text-align: center;
}

.pagination {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem 1rem 1rem;
  background: var(--c-text-light);
  border-top: 1px solid var(--c-soft-blue);
}

.paginationCounts {
  padding: 0 1rem;
}

.paginationBtn {
  width: auto;
  height: 2rem;
  font-size: 1rem;
}

.matrixSelect {
  color: var(--c-secondary-base) !important;
  font-size: 0.825rem !important;
  border: none;
}

.table tr td:last-child {
  border: none;
}

.keyInput {
  background-color: transparent;
  font-weight: 600;
  text-align: center;
  min-width: calc(92px - 1px);
  font-size: 0.875rem !important;
  height: 100%;
  padding: 1rem 0.5rem;
}

.errorVal .keyInput {
  color: #fff;
}

.errorVal {
  background-color: var(--c-error);
  color: #fff;
}

.alertVal {
  background-color: var(--c-alert);
  color: #fff;
}

.countryLabel {
  font-size: .6rem;
  color: #999;
}