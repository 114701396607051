.checkboxWrap {
  display: flex;
  align-items: center;
}

/**
 * Checkbox Five
 */
.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  width: 1.5rem;
  height: 1.6rem;
}

/**
 * Create the box for the checkbox
 */
.checkbox label {
  cursor: pointer;
  position: absolute;
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  top: 0;
  left: 0;
  background: #fff;
  border: 2px solid var(--c-secondary-base);
}

/**
 * Display the tick inside the checkbox
 */
.checkbox label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 4px;
  left: 4px;
  border: 3px solid var(--c-secondary-base);
  border-top: none;
  border-right: none;

  transform: rotate(-45deg);
}

/**
 * Create the hover event of the tick
 */
.checkbox:not(.checkboxDisabled) label:hover::after {
  opacity: 0.3;
}

.checkboxDisabled label {
  border-color: var(--c-gray);
  background: #efefef;
  cursor: default;
}

/**
 * Create the checkbox state for the tick
 */
.checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
  border-color: #fff;
}

.checkbox input[type=checkbox]:checked + label {
  background-color: var(--c-secondary-base);
}

.defaultRightLabel {
  margin-left: .5rem;
}

.defaultLeftLabel {
  margin-right: .5rem;
}