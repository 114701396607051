.container {
  margin: 4rem 1rem 1.5rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left {
  width: 13rem;
}
.middle {
  flex: 1;
}
.right {
  min-width: 13rem;
  margin-left: auto;
}

.title,
.subtitle {
  color: var(--c-text-mid);
  font-weight: 600;
  margin: 0;
}

.title {
  font-size: 2rem;
  line-height: 2.5rem;
}

.subtitle {
  font-size: 0.875rem;
  line-height: 2rem;
}
.subtitle:after {
  content: "";
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}
.loading:before {
  content: " . ";
  color: transparent;
}

@keyframes dots {
  0%,
  30% {
    color: transparent;
  }
  40% {
    color: var(--c-text-mid);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 var(--c-text-mid), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--c-text-mid), 0.5em 0 0 var(--c-text-mid);
  }
}
