.configurationTable {
  border-collapse: collapse;
  margin: 1rem 0;
  border: 1px solid var(--c-gray);
  width: 100%;
}

.configurationErrorTable {
  border-color: var(--c-error);
}

.configurationTable thead th:first-child {
  background-color: #fff;
}

.configurationTable thead th.highlight {
  background-color: var(--c-key);
}

.configurationTable thead th.highlight {
  background-color: var(--c-key);
}

.configurationTable thead th.disabled {
  background-color: var(--c-background-blue) !important;
}

.configurationTable th {
  background-color: var(--c-gray);
  height: 3rem;
  padding: 0;
}

.rSeparator {
  border-right: 1px solid #fff;
}

.configurationTable tbody th {
  width: 3rem;
  padding: 0 1.5rem;
}

.configurationTable td {
  padding: 0;
  border: 1px solid var(--c-background-blue);
}

.configurationTable td.hoverHighlight:hover {
  background-color: var(--c-key-row);
}

.matrixInput {
  height: 3rem;
  border-width: 0;
  font-size: 0.875rem;
  text-align: center;
  background-color: transparent;
}

.textValue {
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leadingColumn {
  background-color: var(--c-key);
}

.errInput {
  background-color: var(--c-error-light);
}

.setLeadingsPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.setLeadingBtn {
  width: auto;
}

.setLeadingBtnSelected {
  background-color: var(--c-key);
}

.card {
  background-color: white;
}

.changeSizeBtn {
  font-weight: bold;
  text-align: center;
  line-height: 1.5rem;
  height: 100%;
  min-width: 1.5rem;
  width: 100%;
  background-color: var(--c-secondary-base) !important;
  color: #fff;
  cursor: pointer;
  border: none;
}

.changeSizeBtn:disabled {
  opacity: 1;
  background-color: var(--c-text-mid) !important;
}
