.text {
  font-family: Montserrat, sans-serif;
}

.size {
}

.light {
  color: var(--c-text-light);
}

.loadingLight:after {
  content: " .";
  animation: dots-light 1s steps(5, end) infinite;
}
.loadingDark:after {
  content: " .";
  animation: dots-dark 1s steps(5, end) infinite;
}

.nolink {
  text-decoration: none;
}

/**
 * source: https://codepen.io/vkjgr/pen/gbPaVx
 */
@keyframes dots-light {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: var(--c-secondary-light);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 var(--c-secondary-light), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--c-secondary-light),
      0.5em 0 0 var(--c-secondary-light);
  }
}

@keyframes dots-dark {
  0%,
  20% {
    color: var(--c-secondary-dark);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: var(--c-secondary-dark);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 var(--c-secondary-dark), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--c-secondary-dark),
      0.5em 0 0 var(--c-secondary-dark);
  }
}
