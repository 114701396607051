.importData {
  background-color: #fff;
}

.importDataInner {
  height: 52px;
  display: flex;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 .5rem;
}

.importDataBtn {
  width: auto;
  margin-right: 1rem;
}

