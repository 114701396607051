.content {
  max-width: 400px;
  margin: 0 auto;
}

.ratesList {
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 3px rgba(164, 169, 179, 0.50184);
}

.rateItem {
  display: flex;
  justify-content: space-between;
  line-height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-bottom: 1px solid #ebedf3;
}

.subTitle {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 4rem;
  margin: 0;
  font-size: 1rem;
}

.rateInput {
  font-size: 1rem;
  text-align: right;
  width: 50px;
  padding: 4px 4px;
}
