.menu {
  margin-left: auto;
  color: var(--c-text-dark);
  position: relative;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-background-blue);
  border-radius: 50%;
  cursor: pointer;
}

.menuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--c-text-dark);
}

.menu:focus {
  outline: none;
}

.menuContent {
  position: absolute;
  top: 3.25rem;
  right: 0;
  background-color: #fff;
  color: var(--c-text-dark);
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.menuItem {
  padding: 0 1rem;
  line-height: 2.75rem;
  white-space: nowrap;
  min-width: 10rem;
  cursor: pointer;
}
