.container {
}

.finishedPriceLists {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.row {
  border-bottom: 1px solid var(--c-gray);
}

.row:hover {
  background-color: var(--c-gray);
}

.cell {
  padding: .5rem;
}